/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author
          social {
            twitter
          }
          contact {
            email
          }
        }
      }
    }
  `)

  const { author, contact, social } = data.site.siteMetadata
  return (
    <div
      style={{
        fontFamily: `'Roboto Slab', serif`,
        fontSize: `2.5rem`,
        lineHeight: 1.5,
      }}
    >
      <p>
        Hi, I'm <strong>{author}</strong>. I am a human-centered product
        engineering leader who builds teams, develops leaders, and helps people
        achieve their full potential. The teams I lead deliver revolutionary
        digital experiences that grow businesses and delight customers.
        {` `}
        <a href={`https://twitter.com/${social.twitter}`}>
          You should follow me on Twitter
        </a>
        {` `}
        or
        {` `}
        <a href={`mailto:${contact.email}`}>say hello by email.</a>
      </p>
    </div>
  )
}

export default Bio
