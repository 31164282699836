import React from "react"
import { Link } from "gatsby"

import Logo from "../components/logo"

import "typeface-nunito"
import "typeface-roboto-slab"
import "./layout.css"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    header = (
      <Link
        style={{
          boxShadow: `none`,
          textDecoration: `none`,
          color: `inherit`,
        }}
        to={`/`}
        aria-label={title}
      >
        <Logo />
      </Link>
    )

    return (
      <div className="wrapper">
        <header className="header">{header}</header>
        <main>{children}</main>
        <footer className="footer">© {new Date().getFullYear()}</footer>
      </div>
    )
  }
}

export default Layout
